import { Box, Button, Heading, Link, Stack, Text } from '@chakra-ui/react';

const ErrorPage = () => {
  return (
    <Box>
      <Stack gap={2} alignItems="center" justifyContent="center" w="90vw" h="80vh">
        <Heading mb={0}>Error 404</Heading>
        <Text>Page not found!</Text>
        <Link href="/">
          <Button>Go home</Button>
        </Link>
      </Stack>
    </Box>
  );
};

export async function getStaticProps() {
  return { props: {} };
}

export default ErrorPage;
